import { Link } from 'react-router-dom';
function Foot(){
  return(
      <div>

<div className="container-fluid  bg-dark">
      <section className="service">
      <div className="row container-fluid he text-white justify-content-center g-0 p-0">







      <div className="col-12 col-md-3">
  <h4 className="py-3 case">Service</h4>
  <div className="links-container">
    <Link className="mx-md-2" to="/One-Day-Picnic-Spot-Near-Pune-for-Family">
      <i className="fas fa-umbrella-beach darkcolor"></i> One Day Picnic Spot Near Pune for Family
    </Link>
    <Link className="mx-md-2" to="/One-Day-Trip-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor "></i> One Day Trip Near Pune
    </Link>
    <Link className="mx-md-2" to="/Picnic-Spot-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Picnic Spot Near Pune
    </Link>
    <Link className="mx-md-2" to="/Picnic-Destination-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Picnic Destination Near Pune
    </Link>
    <Link className="mx-md-2" to="/Corporate-Outing-Resort-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Corporate Outing Resorts Near Pune
    </Link>
    <Link className="mx-md-2" to="/Best-cottage-near-mumbai">
      <i className="fas fa-umbrella-beach darkcolor"></i> Best Cottages Near Pune
    </Link>
    <Link className="mx-md-2" to="/Agri-Tourism-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Agri Tourism Near Pune
    </Link>
    <Link className="mx-md-2" to="/Destination-Wedding-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Destination Wedding Near Pune
    </Link>
    <Link className="mx-md-2" to="/Hurda-Party-Places-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Hurda Party Places Near Pune
    </Link>
    <Link className="mx-md-2" to="/Water-Park-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Water Park Near Pune
    </Link>
    <Link className="mx-md-2" to="/School-Picnic-Spots-Near-Pune-for-Students">
      <i className="fas fa-umbrella-beach darkcolor"></i> School Picnic Spots Near Pune for Students
    </Link>
   
     
  </div>


      
     
    </div>


    <div className="col-12 col-md-3">
      <h4 className="py-3 case">Service</h4>
      
      <div className='links-container'>
      <Link className="mx-md-2" to="/One-Day-Adventure-Resorts-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> One Day Adventure Resorts Near Pune
    </Link>
    <Link className="mx-md-2" to="/Resort-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Resort Near Pune
    </Link>
    <Link className="mx-md-2" to="/Forest-Resorts-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Forest Resorts Near Pune
    </Link>
    <Link className="mx-md-2" to="/Nature-resorts-near-pune-for-weekend">
      <i className="fas fa-umbrella-beach darkcolor"></i> Nature resorts near Pune for weekend
    </Link>
    <Link className="mx-md-2" to="/Mango-Mahotsav-in-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Mango Mahotsav in Pune
    </Link>
    <Link className="mx-md-2" to="/Krushi-Paryatan-Kendra-near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Krushi Paryatan Kendra near Pune
    </Link>

    
    <Link className="mx-md-2" to="/Camping-Places-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Camping Places Near Pune
    </Link>
    <Link className="mx-md-2" to="/Activities-Resort-Near-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Activities Resort Near Pune
    </Link>
    <Link className="mx-md-2" to="/Agro-Tourism-Near-Mumbai-for-Family">
      <i className="fas fa-umbrella-beach darkcolor"></i> Agro Tourism Near Mumbai for Family
    </Link>

    <Link className="mx-md-2" to="/One-Day-Picnic-Spot-From-Mumbai">
      <i className="fas fa-umbrella-beach darkcolor"></i> One Day Picnic Spot From Mumbai
    </Link>
    <Link className="mx-md-2" to="/One-Day-Picnic-Packages-Near-Mumbai">
      <i className="fas fa-umbrella-beach darkcolor"></i> One Day Picnic Packages Near Mumbai
    </Link>
    

  </div>
    </div>



    <div className="col-12 col-md-3 text-white">
      <h4 className="py-3 case">Service</h4>
      <div className='links-container'>

      <Link className="mx-md-2" to="/School-Picnic-Spots-in-Mumbai">
      <i className="fas fa-umbrella-beach darkcolor"></i> School Picnic Spots in Mumbai
    </Link>
      <Link className="mx-md-2" to="/Resort-Near-Mumbai">
      <i className="fas fa-umbrella-beach darkcolor"></i> Resort Near Mumbai
    </Link>
    <Link className="mx-md-2" to="/Team-Outing-Places-Near-Mumbai">
      <i className="fas fa-umbrella-beach darkcolor"></i> Team Outing Places Near Mumbai
    </Link>
    <Link className="mx-md-2" to="/Corporate-Outing-Near-Mumbai">
      <i className="fas fa-umbrella-beach darkcolor"></i> Corporate Outing Near Mumbai
    </Link>
    <Link className="mx-md-2" to="/Picnic-Spot-Near-Nashik">
      <i className="fas fa-umbrella-beach darkcolor"></i> Picnic Spot Near Nashik
    </Link>
    <Link className="mx-md-2" to="/Farm-House-On-Rent-in-Pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Farm House On Rent in Pune
    </Link>
    <Link className="mx-md-2" to="/Picnic-Spot-Near-Pimpri-Chinchwad">
      <i className="fas fa-umbrella-beach darkcolor"></i> Picnic Spot Near Pimpri Chinchwad
    </Link>
    <Link className="mx-md-2" to="/Malthan-Agri-Tourism">
      <i className="fas fa-umbrella-beach darkcolor"></i> Malthan Agri Tourism
    </Link>
    <Link className="mx-md-2" to="/Agri-Tourism-Near-Ahmednagar">
      <i className="fas fa-umbrella-beach darkcolor"></i> Agri Tourism Near Ahmednagar
    </Link>
    <Link className="mx-md-2" to="/Best-hotels-in-Ranjangaon-Ganpati">
      <i className="fas fa-umbrella-beach darkcolor"></i> Best hotels in Ranjangaon Ganpati
    </Link>
    {/* <Link className="mx-md-2" to="/Ranjangaon-Bhakta-Niwas-Online-Booking">
      <i className="fas fa-umbrella-beach darkcolor"></i> Ranjangaon Bhakta Niwas Online Booking
    </Link> */}
    <Link className="mx-md-2" to="/Best-cottage-near-pune">
      <i className="fas fa-umbrella-beach darkcolor"></i> Best Cottages Near Pune
    </Link>


      </div>
    </div>


    <div className="col-12 col-md-3">
      <h4 className="py-3 case">Service</h4>
      <h4 className="case darkcolor text-white"> Shinde Krushi Paryatan(Shinde Agri Tourism)</h4>
      <p><strong className='darkcolor fw-bold'>Phone:</strong><br />
      <i className="fas fa-phone-alt text-white"></i> <a href="tel:+919356007002" className='d-inline text-white'>+91 9356007002</a><br />
        <i className="fas fa-phone-alt text-white"></i> <a href="tel:+9190759 90202" className='d-inline text-white'>+91 9075990202</a>
      
      </p>
      <p className='row w-100'><strong className='col-12 darkcolor fw-bold'>Email:</strong><br />
        <a href="mailto:booking@shindekrushiparyatan.com" className='col-12 overflow-hidden text-white'>booking@shindekrushiparyatan.com</a>
      </p>
      <p className='text-white'><strong className='fw-bold darkcolor'>Address:</strong><br />
        Shinde Krushi Paryatan(Shinde Agri Tourism) :<br />
        At post - Malthan (Pabal Road) <br />
                            Taluka Shirur
                            District Pune,
                            <br /> Maharashtra - 412218

      </p>
      <div className="mt-4">
        <h5 className="case darkcolor">Our Location:</h5>

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.5547922308438!2d74.22163490000001!3d18.834895699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc32bb61bb9bd89%3A0xe4a14a349669cb53!2sShinde%20Agri%20Tourism%20(%20Shinde%20krushi%20paryatan%20)!5e1!3m2!1sen!2sin!4v1731325585975!5m2!1sen!2sin" width="600" height="150" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

      </div>
    </div>

    <hr />
   
    <p className="text-white text-center">
  All Rights Reserved @ <span className="darkcolor">Shinde Agri Tourism </span> 2024 Developed By
  <a href="https://www.dexterintelligence.com/" className="d-inline" target="_blank" rel="noopener noreferrer">
    <span className="darkcolor">&nbsp;Dexter Intelligence</span>
  </a> | Hosted By
  <a href="https://www.urvahosting.com/" className="d-inline" target="_blank" rel="noopener noreferrer">
    <span className="darkcolor">&nbsp;Urva Hosting</span>
  </a>
</p>
      </div>
        </section>
      </div>
    </div>
  );
}

export default Foot;


