import React from 'react';
import '../css/AttractionsInMalthan.css';

const AttractionsInMalthan = () => {
  const attractions = [
    {
      name: 'Malthan Village Temple',
      description: 'A serene and beautiful temple located in the heart of Malthan village. Known for its peaceful surroundings and spiritual significance.',
      distance: 'Within the village',
      image: '/images/attraction/1.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Local Handicraft Market',
      description: 'Explore the local handicraft market where you can buy traditional crafts and souvenirs made by local artisans.',
      distance: '5 km',
      image: '/images/attraction/3.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Malthan Nature Walks',
      description: 'Take a guided nature walk through the lush forests and scenic landscapes of Malthan. Spot diverse wildlife and immerse yourself in nature.',
      distance: '10 km',
      image: '/images/attraction/4.jpg',
      link: 'https://goo.gl/maps/example'
    },
    // {
    //   name: 'Shinde Waterfall',
    //   description: 'A hidden gem! A beautiful waterfall located just outside Malthan, perfect for a short hike and picnic with breathtaking views.',
    //   distance: '12 km',
    //   image: '/images/attraction/5.jpg',
    //   link: 'https://goo.gl/maps/example'
    // },
    {
      name: 'Birds Watching',
      description: 'Experience the joy of bird watching at Shinde Agri Tourism! 🦜🌳 Spot beautiful birds in their natural habitat and reconnect with nature.',
      distance: 'At Shinde agri tourism',
      image: '/images/attraction/6.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Archery Games',
      description: 'Experience the thrill of archery at Shinde Agri Tourism! 🏹🎯 Test your precision and enjoy this fun outdoor activity in a scenic farm setting.',
      distance: 'At Shinde Agri Tourism',
      image: '/images/attraction/11.avif',
      link: 'https://goo.gl/maps/example'
    },    
    {
      name: 'Bull riding',
      description: 'Feel the thrill of traditional bull riding at Shinde Agri Tourism! 🐂🌾 An adventure rooted in culture and excitement.',
      distance: 'At Shinde agri tourism',
      image: '/images/attraction/8.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Swiming Pool ',
      description: 'Dive into relaxation at Shinde Agri Tourism’s swimming pool! 🌊☀️ Cool off and unwind surrounded by nature’s beauty.',
      distance: 'At Shinde agri tourism',
      image: '/images/attraction/9.jpg',
      link: 'https://goo.gl/maps/example'
    },
    {
      name: 'Tasty home made food',
      description: 'Savor the flavors of tasty homemade food at Shinde Agri Tourism! 🍲✨ Enjoy authentic, farm-fresh meals made with love.',
      distance: 'At Shinde agri tourism',
      image: '/images/attraction/10.jpg',
      link: 'https://goo.gl/maps/example'
    },
    
  ];

  return (








    <div>


      
<section className="breadcrumb-main pb-20 pt-14" style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}>
      <div className="section-shape section-shape1 top-inherit bottom-0" style={{ backgroundImage: 'url(images/shape8.png)' }}></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 className="mb-3">Attraction</h1>

          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>

    
    <section className="attractions-in-malthan">
      <div className="container">
        <h2 className="section-title text-white">Attractions in Malthan - Shinde Agri Tourism</h2>
        <p className="section-description text-white">Malthan and the surrounding areas are filled with cultural landmarks, nature spots, and unique experiences that make your stay at Shinde Agri Tourism even more special. Here are some of the must-visit attractions.</p>
        
        <div className="attractions-grid">
          {attractions.map((attraction, index) => (
            <div key={index} className="attraction-card">
              <img src={attraction.image} alt={attraction.name} className="attraction-image" />
              <div className="attraction-details">
                <h3 className=" darkcolor">{attraction.name}</h3>
                <p className="attraction-description">{attraction.description}</p>
                <p className='darkcolor'><strong className='darkcolor'>Distance:</strong> {attraction.distance}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

    </div>  );
};

export default AttractionsInMalthan;
