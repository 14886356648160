import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './pages/About';
import GalleryOne from './pages/Gallery';
import Contact from './pages/Contact';
import EnquiryForm from './pages/EnquiryForm';
import NearbyPlaces from './pages/NearbyPlaces';
import AttractionsInMalthan from './pages/AttractionsInMalthan';
import Onedaypicnic from './pages/One Day Picnic Spot Near Pune for Family';
import Foot from './components/Footer';
import Onedattripnear from './pages/One Day Trip Near Pune';
import ScrollToTop from './components/ScrollToTop';
import Picnicsportnearme from './pages/Picnic Spot Near Pune';
import Picnicdestination from './pages/Picnic Destination Near Pune';
import Corporateout from './pages/Corporate Outing Resort Near Pune';
import AgriTourism from './pages/Agri Tourism Near Pune';
import Destination from './pages/Destination Wedding Near Pune';
import Hurdaparty from './pages/Hurda Party Places Near Pune';
import Waterparknear from './pages/Water Park Near Pune';
import Schoolpivnicsport from './pages/School Picnic Spots Near Pune for Students';
import Adventureonedayinpune from './pages/One Day Adventure Resorts Near Pune.js';
import Resortnearme from './pages/Resort Near Pune.js';
import Forestresort from './pages/Forest Resorts Near Pune.js';
import Natureresortnearpune from './pages/Nature resorts near pune for weekend.js';
import Mangomahotsav from './pages/Mango Mahotsav in Pune.js';
import Krushiparyatan from './pages/Krushi Paryatan Kendra near Pune.js';
import Campingplaces from './pages/Camping Places Near Pune.js';
import Activitiesresort from './pages/Activities Resort Near Pune.js';
import Agrotourismnearmumbai from './pages/Agro Tourism Near Mumbai for Family.js';
import Onedaypicnicspot from './pages/One Day Picnic Spot From Mumbai.js';
import Onedaypicnicpackages from './pages/One Day Picnic Packages Near Mumbai.js';
import Schoolpicnicsports from './pages/School Picnic Spots in Mumbai.js';
import Resortnearmumbai from './pages/Resort Near Mumbai.js';
import Teamouting from './pages/Team Outing Places Near Mumbai.js';
import Corporateoutingnearmumbai from './pages/Corporate Outing Near Mumbai.js';
import Picnicspotnearnashiks from './pages/Picnic Spot Near Nashik.js';
import Farmhouseonrentinpune from './pages/Farm House On Rent in Pune.js';
import Picnicspotpimpri from './pages/Picnic Spot Near Pimpri Chinchwad.js';
import Malthanagri from './pages/Malthan Agri Tourism.js';
import Agritourismnear from './pages/Agri Tourism Near Ahmednagar.js';
import Besthotalsinranjan from './pages/Best hotels in Ranjangaon Ganpati.js';
import Ranjangaonbhakta from './pages/Ranjangaon Bhakta Niwas Online Booking.js';
import Packages from './pages/Packages.js';
import Termcondition from './pages/Termcondition.js';
import Ashtavinayak from './pages/Ashtavinayak Darshan stay.js';
import Activities from './pages/Activities.js';
import Privacy from './pages/Privacy.js';
import Videos from './pages/videoes.js';
import Imagess from './pages/360.js';
import RefundPolicy from './pages/Refund policy.js';
import ContactButtons from './components/ContactButtons.js';
import Bestcottagepune from './pages/Best cotage near pune.js';
import Bestcottagenearmumbai from './pages/Best cattage near mumbai.js';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <ContactButtons/>
      {/* <CookieConsentBanner/> */}
      <Header />
      <Routes>

        <Route path="/" element={<Hero />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<GalleryOne />} />
        <Route path="/Near-places" element={<NearbyPlaces />} />
        <Route path="/package" element={<Packages />} />
        <Route path="/Term-condition" element={<Termcondition />} />
        <Route path="/Privacy-policy" element={<Privacy />} />
        <Route path="/Refund-policy" element={<RefundPolicy />} />
        <Route path="/Attraction" element={<AttractionsInMalthan />} />
        <Route path="/ashtavinayak-darshan-stay" element={<Ashtavinayak />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/360-images" element={<Imagess />} />
        <Route path="/Activities" element={<Activities />} />
        <Route path="/Enquiry" element={<EnquiryForm />} />
        <Route path="/One-Day-Picnic-Spot-Near-Pune-for-Family" element={<Onedaypicnic />} />
        <Route path="/One-Day-Trip-Near-Pune" element={<Onedattripnear />} />
        <Route path="/Picnic-Spot-Near-Pune" element={<Picnicsportnearme />} />
        <Route path="/Picnic-Destination-Near-Pune" element={<Picnicdestination />} />
        <Route path="/Corporate-Outing-Resort-Near-Pune" element={<Corporateout />} />
        <Route path="/Agri-Tourism-Near-Pune" element={<AgriTourism />} />
        <Route path="/Destination-Wedding-Near-Pune" element={<Destination />} />
        <Route path="/Hurda-Party-Places-Near-Pune" element={<Hurdaparty />} />
        <Route path="/Water-Park-Near-Pune" element={<Waterparknear />} />
        <Route path="/School-Picnic-Spots-Near-Pune-for-Students" element={<Schoolpivnicsport />} />
        <Route path="/One-Day-Adventure-Resorts-Near-Pune" element={<Adventureonedayinpune />} />
        <Route path="/Resort-Near-Pune" element={<Resortnearme />} />
        <Route path="/Forest-Resorts-Near-Pune" element={<Forestresort />} />
        <Route path="/Nature-resorts-near-pune-for-weekend" element={<Natureresortnearpune />} />
        <Route path="/Mango-Mahotsav-in-Pune" element={<Mangomahotsav />} />
        <Route path="/Krushi-Paryatan-Kendra-near-Pune" element={<Krushiparyatan />} />
        <Route path="/Camping-Places-Near-Pune" element={<Campingplaces />} />
        <Route path="/Activities-Resort-Near-Pune" element={<Activitiesresort />} />
        <Route path="/Agro-Tourism-Near-Mumbai-for-Family" element={<Agrotourismnearmumbai />} />
        <Route path="/One-Day-Picnic-Spot-From-Mumbai" element={<Onedaypicnicspot />} />
        <Route path="/One-Day-Picnic-Packages-Near-Mumbai" element={<Onedaypicnicpackages />} />
        <Route path="/School-Picnic-Spots-in-Mumbai" element={<Schoolpicnicsports />} />
        <Route path="/Resort-Near-Mumbai" element={<Resortnearmumbai />} />
        <Route path="/Team-Outing-Places-Near-Mumbai" element={<Teamouting />} />
        <Route path="/Corporate-Outing-Near-Mumbai" element={<Corporateoutingnearmumbai />} />
        <Route path="/Picnic-Spot-Near-Nashik" element={<Picnicspotnearnashiks />} />
        <Route path="/Farm-House-On-Rent-in-Pune" element={<Farmhouseonrentinpune />} />
        <Route path="/Picnic-Spot-Near-Pimpri-Chinchwad" element={<Picnicspotpimpri />} />
        <Route path="/Malthan-Agri-Tourism" element={<Malthanagri />} />
        <Route path="/Agri-Tourism-Near-Ahmednagar" element={<Agritourismnear />} />
        <Route path="/Best-hotels-in-Ranjangaon-Ganpati" element={<Besthotalsinranjan />} />
        {/* <Route path="/Ranjangaon-Bhakta-Niwas-Online-Booking" element={<Ranjangaonbhakta />} /> */}
        <Route path="/Best-cottage-near-pune" element={<Bestcottagepune />} />
        <Route path="/Best-cottage-near-mumbai" element={<Bestcottagenearmumbai/>} />


      </Routes>
      <Foot />
    </Router>


  );
}

export default App;
