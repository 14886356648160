
import React, { useState } from 'react';

const BusRatesTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [selectedPackage, setSelectedPackage] = useState('');

  const packagesData = [
    {
      package: "One Day Return Package",
      details: "9:30 am to 5:30 pm",
      rate: "₹800 per adult, ₹550 per child (4-10 years) Including Breakfast, Lunch, High Tea & All activities",
      note: "Negotiable for large groups"
    },
    {
      package: "Night Stay Package - 24 hrs",
      details: "12:00 noon to 10:00 am OR 5:00 pm to 3:00 pm",
      rate: "₹2000 per adult, ₹1200 per child (4-10 years) (Including Breakfast, Lunch, Dinner & All activities)",
      note: "Negotiable for large groups"
    },
    {
      package: "Night Stay Half Package",
      details: "5:00 pm to 10:00 am",
      note: "₹1500 Per Adult, ₹1000 per child (4-10 years) (Including Evening & Morning Breakfast , Dinner & All Activities"
    },
    {
      package: "Corporate Picnic Packages",
      details: "Charges applicable as per event"
    },
    {
      package: "School Picnic Packages",
      details: `
        Nursery to Sr. Kg: ₹400<br/>
        1st to 4th Std: ₹450<br/>
        5th to 7th Std: ₹500<br/>
        8th to 10th Std: ₹600<br/>
        11th & Above: ₹650
      `
    },
    {
      package: "Wedding Destination Packages",
      details: "Details provided on call"
    },
    {
      package: "Pre Wedding Shoot Package",
      details: "Time period: 2 hours",
      rate: "₹2000"
    }
  ];

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    margin: "20px auto"
  };

  const thStyle = {
    backgroundColor: "#106b30",
    color: "white",
    padding: "8px",
    border: "1px solid #ddd"
  };

  const tdStyle = {
    padding: "8px",
    border: "1px solid #ddd",
    textAlign: "center"
  };

  const handleWhatsAppClick = (packageName) => {
    const message = `Hi, I would like to inquire about the ${packageName}.`;
    setWhatsappNumber('+919356007002'); // Replace with your WhatsApp number
    setSelectedPackage(packageName); // Set the selected package
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handlePayment = () => {
    // Ensure Razorpay is loaded before proceeding
    if (window.Razorpay) {
      // Example for Razorpay integration
      const options = {
        key: 'YOUR_RAZORPAY_KEY', // Replace with your Razorpay key
        amount: 800 * 100, 
        currency: "INR",
        name: "Agritourism Package",
        description: `Booking for ${selectedPackage}`,
        image: "https://yourwebsite.com/logo.png", // Add your logo
        handler: function(response) {
          alert("Payment successful! Payment ID: " + response.razorpay_payment_id);
          // Handle successful payment (save order, etc.)
        },
        prefill: {
          name: "Customer Name",
          email: "customer@example.com",
          contact: "+91 9356007002"
        },
        notes: {
          address: "Customer Address"
        },
        theme: {
          color: "#106b30"
        }
      };

      try {
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } catch (error) {
        console.error("Razorpay initialization failed", error);
        alert("Something went wrong. Please try again later.");
      }
    } else {
      alert("Razorpay SDK is not loaded. Please try again later.");
    }
  };

  return (
    <div className='container p-4'>
      <h3>Our Packages</h3>
      <div className='table-responsive'>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Package Name</th>
              <th style={thStyle}>Details</th>
              <th style={thStyle}>Rate</th>
              <th style={thStyle}>Book Now</th>
            </tr>
          </thead>
          <tbody>
            {packagesData.map((pkg, index) => (
              <tr key={index} style={index % 2 === 0 ? { backgroundColor: "#f2f2f2" } : null}>
                <td style={tdStyle}>{pkg.package}</td>
                <td style={tdStyle} dangerouslySetInnerHTML={{ __html: pkg.details }} />
                <td style={tdStyle}>{pkg.rate || pkg.note}</td>
                <td style={tdStyle}>
                  <button
                    onClick={() => handleWhatsAppClick(pkg.package)}
                    style={{ cursor: 'pointer', padding: '8px', backgroundColor: '#106b30', borderRadius: '10px', color: 'white' }}
                  >
                    Book Now
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for WhatsApp Number */}
      {showModal && (
        <div style={{
          position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <div style={{
            backgroundColor: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center', minWidth: '300px'
          }}>
            <h4>Contact Us on WhatsApp</h4>
            <p>For inquiries about the selected package, message us on WhatsApp at:</p>
            <p><strong>{whatsappNumber}</strong></p>
            <button
              onClick={() => window.open(`https://wa.me/${whatsappNumber}?text=Hi%2C%20I%20would%20like%20to%20inquire%20about%20the%20${encodeURIComponent(selectedPackage)}.`)}
              style={{
                padding: '10px 20px', backgroundColor: '#106b30', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer'
              }}
            >
              Chat on WhatsApp
            </button>
            <br />
            <h4>Proceed to Payment</h4>
            <button onClick={handlePayment} style={{
              marginTop: '10px', padding: '10px 20px', backgroundColor: '#ff9800', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer'
            }}>
              Book Now
            </button>
            <br />
            <button onClick={closeModal} style={{
              marginTop: '10px', padding: '8px 16px', backgroundColor: '#ccc', border: 'none', borderRadius: '5px', cursor: 'pointer'
            }}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusRatesTable;
